import { Grid } from "@mui/material";
import { FormSelect } from "app/componentes/formularios/FormSelect";
import { FormTextInput } from "app/componentes/formularios/FormTextInput";
import { pintaOpcionesSelect } from "app/helpers/libFunciones";

export const tipoPaquete = [
	{ id: "M", nombre: "MUESTRA" },
	{ id: "0", nombre: "Documentos, papel" },
	{ id: "1", nombre: "Bolsa de envío Nacex normalizada" },
	{ id: "2", nombre: "Caja de cartón, varias medidas" },
	{ id: "3", nombre: "TUBO" },
	{ id: "X", nombre: "Small" },
	{ id: "Y", nombre: "Medium" },
	{ id: "Z", nombre: "Large" },
	{ id: "D", nombre: "DOCUMENTO" },
	{ id: "5", nombre: "PAQ 5" },
	{ id: "6", nombre: "PAQ 10" },
	{ id: "7", nombre: "PAQ 20" },
	{ id: "8", nombre: "PAQ 20" },
	{ id: "B", nombre: "BICIBOX" },
	{ id: "E", nombre: "EQUIPAJES" },
	{ id: "C", nombre: "BICIBOX I/V" },
	{ id: "F", nombre: "EQUIPAJES I/V" },
	{ id: "G", nombre: "Tamaño pequeño 48 horas. Rango 15-25º" },
	{ id: "H", nombre: "Tamaño mediano 48 horas. Rango 15-25º" },
	{ id: "I", nombre: "Tamaño grande 48 horas. Rango 15-25º" },
	{ id: "J", nombre: "Tamaño pequeño 96 horas. Rango 15-25º" },
	{ id: "K", nombre: "Tamaño mediano 96 horas. Rango 15-25º" },
	{ id: "R", nombre: "Tamaño pequeño 48 horas. Rango 2-8º" },
	{ id: "S", nombre: "Tamaño mediano 48 horas. Rango 2-8º" },
	{ id: "T", nombre: "Tamaño grande 48 horas. Rango 2-8º" },
	{ id: "U", nombre: "Tamaño pequeño 96 horas. Rango 2-8º" },
	{ id: "V", nombre: "Tamaño mediano 96 horas. Rango 2-8º" },
    { id: "D", nombre: "Documentos, papel. (Para envíos fuera de España, Portugal y Andorra)" },
    { id: "M", nombre: "Caja de cartón, varias medidas. (Para envíos en España, Portugal y Andorra)" },
];

export const tipoReembolso = [
	{ id: "N", nombre: "No se solicita el servicio complementario" },
	{ id: "O", nombre: "Servicio complementario de Reembolso a cargo de la agencia de Origen" },
	{ id: "D", nombre: "Servicio complementario de Reembolso a cargo de la agencia de Destino" },
	{ id: "A", nombre: "Adelanto del importe indicado, pago en la recogida." },
];

export const tipoGestionTramite = [
	{ id: "N", nombre: "No se solicita el servicio complementario" },
	{ id: "O", nombre: "Servicio complementario de gestión en origen" },
	{ id: "D", nombre: "Servicio complementario de gestión en entrega" },
	{ id: "A", nombre: "Servicio complementario de gestión en origen y en entrega" },
	{ id: "R", nombre: "Servicio complementario de trámite en origen" },
	{ id: "E", nombre: "Servicio complementario de trámite en entrega" },
	{ id: "T", nombre: "Servicio complementario de trámite en origen y en entrega" },
];

export const tipoSeguro = [
	{ id: "N", nombre: "No asegurado. (Seguro básico hasta 3.000 €)" },
	{ id: "A", nombre: "Mercancía general superior a 3.000 €" },
	{ id: "B", nombre: "Joyería" },
	{ id: "C", nombre: "Telefonía" },
	{ id: "D", nombre: "Mercancía especial/exclusiva" },
	{ id: "E", nombre: "Armas munición, accesorios y repuestos" },
	{ id: "F", nombre: "Loteria (en administración de lotería), cheques gourmet, entradas espectáculos, Smartbox" },
];

export const tipoGestionAlbaran = [
	{ id: "N", nombre: "Sin gestión." },
	{ id: "D", nombre: "Digitalizar." },
	{ id: "R", nombre: "Retorno del albarán al cliente." },
];

export const tipoVehiculo = [
	{ id: "C", nombre: "Coche" },
	{ id: "M", nombre: "Moto" },
];

export const aceptarKilometros = [
	{ id: "N", nombre: "No" },
	{ id: "Y", nombre: "Si" },
];

export default function CamposEnviosNacex() {
	return (
		<>
			<Grid item lg={3} xs={12}>
				<FormSelect fast name="tipoPesoVol" label="Tipo de paquete" defaultValue="2">
					{pintaOpcionesSelect(tipoPaquete, "id", "nombre", "tipoPaquete", 0)}
				</FormSelect>
			</Grid>
            <Grid item lg={3} xs={12}>
				<FormSelect fast name="tipoReembolso" label="Tipo de reembolso/adelanto" defaultValue="N">
					{pintaOpcionesSelect(tipoReembolso, "id", "nombre", "tipoReembolso", 0)}
				</FormSelect>
			</Grid>
            <Grid item lg={3} xs={12}>
				<FormSelect fast name="gestionTramite" label="Código de gestión o trámite" defaultValue="N">
					{pintaOpcionesSelect(tipoGestionTramite, "id", "nombre", "tipoGestionTramite", 0)}
				</FormSelect>
			</Grid>
            <Grid item lg={3} xs={12}>
				<FormSelect fast name="tipoSeguro" label="Tipo de seguro" defaultValue="N">
					{pintaOpcionesSelect(tipoSeguro, "id", "nombre", "tipoSeguro", 0)}
				</FormSelect>
			</Grid>
            <Grid item lg={3} xs={12}>
				<FormTextInput type="number" step="1" fast name="importeValorAsegurar" label="Importe valor asegurar (Euros)" />
			</Grid>
            <Grid item lg={3} xs={12}>
				<FormSelect fast name="gestionAlbaran" label="Gestión del albarán" defaultValue="N">
					{pintaOpcionesSelect(tipoGestionAlbaran, "id", "nombre", "tipoGestionAlbaran", 0)}
				</FormSelect>
			</Grid>
			<Grid item lg={3} xs={12}>
				<FormSelect fast name="tipoVehiculo" label="Tipo de vehículo" defaultValue="C">
					{pintaOpcionesSelect(tipoVehiculo, "id", "nombre", "tipoVehiculo", 0)}
				</FormSelect>
			</Grid>
			<Grid item lg={3} xs={12}>
				<FormSelect fast name="aceptarKilometros" label="¿Aceptar kilometros?" defaultValue="N">
					{pintaOpcionesSelect(aceptarKilometros, "id", "nombre", "aceptarKilometros", 0)}
				</FormSelect>
			</Grid>
			<Grid item lg={3} xs={12}>
				<FormTextInput
					fast
					name="descDeclarado"
					label="Descripción de la mercancía"
					tooltip="Descripción de la mercancía (Para envíos internacionales)"
				/>
			</Grid>
		</>
	);
}
